import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const Plus = (): JSX.Element => {

  const svgProps = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: .2
      }
    }
  };
  const draw = {
    hidden: { opacity: 1 },
    visible: {
      d: [
        "M 100,30 L 100,170, M30,100 L170, 100",
        "M 170,30 L 30,170, M30,30 L170, 170",
        "M 100,30 L 100,170, M 100,30 L 100,170",
        "M 100,100 L 100,100, M100,100 L100, 100",
        "M 100,30 L 100,170, M30,100 L170, 100"
      ],
      transition: {
        repeat: Infinity,
        ease: "easeInOut",
        duration: 3,
        delay: .5,
        times: [0, 0.16, 0.33, 0.5, 0.66]
      }
    }
  };

  return (
    <AnimatePresence mode='wait'>
      <motion.svg
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '2.8vw'
        }}
        viewBox="0 0 200 200"
        variants={svgProps}
        initial={"hidden"}
        whileInView={"visible"}
      >
        <motion.path
          style={{
            stroke: "#E3FDA5",
            strokeLinecap: "round",
            strokeWidth: 30,
            fill: "transparent"
          }}
          variants={draw}
        />
      </motion.svg>
    </AnimatePresence>

  );
}
export default Plus;
