import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const Chevron = (): JSX.Element => {
    const svgProps = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                delay: .2
            }
        }
    };
    const draw = {
        hidden: { opacity: 1 },
        visible: {
            d: [
                "M30.5358 139.543C34.3898 143.397 40.6385 143.397 44.4925 139.543L103.492 80.5439L163.02 140.072C166.874 143.926 173.123 143.926 176.977 140.072C180.831 136.218 180.831 129.97 176.977 126.116L111.447 60.5863C111.173 60.2221 110.87 59.8727 110.538 59.5409C106.684 55.6869 100.435 55.6869 96.5811 59.5409L30.5358 125.586C26.6818 129.44 26.6818 135.689 30.5358 139.543Z",
                "M30.5358 139.543C34.3898 143.397 40.6385 143.397 44.4925 139.543L103.492 80.5439L163.02 140.072C166.874 143.926 173.123 143.926 176.977 140.072C180.831 136.218 180.831 129.97 176.977 126.116L111.447 60.5863C111.173 60.2221 110.87 59.8727 110.538 59.5409C106.684 55.6869 100.435 55.6869 96.5811 59.5409L30.5358 125.586C26.6818 129.44 26.6818 135.689 30.5358 139.543Z M30.5358 139.543C34.3898 143.397 40.6385 143.397 44.4925 139.543L103.492 80.5439L163.02 140.072C166.874 143.926 173.123 143.926 176.977 140.072C180.831 136.218 180.831 129.97 176.977 126.116L111.447 60.5863C111.173 60.2221 110.87 59.8727 110.538 59.5409C106.684 55.6869 100.435 55.6869 96.5811 59.5409L30.5358 125.586C26.6818 129.44 26.6818 135.689 30.5358 139.543Z M30.5358 139.543C34.3898 143.397 40.6385 143.397 44.4925 139.543L103.492 80.5439L163.02 140.072C166.874 143.926 173.123 143.926 176.977 140.072C180.831 136.218 180.831 129.97 176.977 126.116L111.447 60.5863C111.173 60.2221 110.87 59.8727 110.538 59.5409C106.684 55.6869 100.435 55.6869 96.5811 59.5409L30.5358 125.586C26.6818 129.44 26.6818 135.689 30.5358 139.543Z",
                "M56.709 76.9792C58.9877 79.2565 62.6821 79.2565 64.9608 76.9792L99.8435 42.1181L135.039 77.2921C137.318 79.5693 141.012 79.5693 143.291 77.2921C145.57 75.0148 145.57 71.3226 143.291 69.0454L104.547 30.3256C104.385 30.1105 104.206 29.904 104.01 29.7079C101.731 27.4307 98.0364 27.4307 95.7577 29.7079L56.709 68.7325C54.4303 71.0098 54.4303 74.702 56.709 76.9792Z M56.709 123.979C58.9877 126.256 62.6821 126.256 64.9608 123.979L99.8435 89.1181L135.039 124.292C137.318 126.569 141.012 126.569 143.291 124.292C145.57 122.015 145.57 118.323 143.291 116.045L104.547 77.3256C104.385 77.1105 104.206 76.904 104.01 76.7079C101.731 74.4307 98.0364 74.4307 95.7577 76.7079L56.709 115.733C54.4303 118.01 54.4303 121.702 56.709 123.979Z M56.709 169.979C58.9877 172.256 62.6821 172.256 64.9608 169.979L99.8435 135.118L135.039 170.292C137.318 172.569 141.012 172.569 143.291 170.292C145.57 168.015 145.57 164.323 143.291 162.045L104.547 123.326C104.385 123.11 104.206 122.904 104.01 122.708C101.731 120.431 98.0364 120.431 95.7577 122.708L56.709 161.733C54.4303 164.01 54.4303 167.702 56.709 169.979Z",
                "M143.291 123.021C141.012 120.744 137.318 120.744 135.039 123.021L100.157 157.882L64.9608 122.708C62.6821 120.431 58.9877 120.431 56.709 122.708C54.4303 124.985 54.4303 128.677 56.709 130.955L95.4527 169.674C95.6151 169.89 95.7943 170.096 95.9905 170.292C98.2691 172.569 101.964 172.569 104.242 170.292L143.291 131.267C145.57 128.99 145.57 125.298 143.291 123.021Z M143.291 76.0208C141.012 73.7435 137.318 73.7435 135.039 76.0208L100.157 110.882L64.9608 75.7079C62.6821 73.4307 58.9877 73.4307 56.709 75.7079C54.4303 77.9852 54.4303 81.6774 56.709 83.9546L95.4527 122.674C95.6151 122.89 95.7943 123.096 95.9905 123.292C98.2691 125.569 101.964 125.569 104.242 123.292L143.291 84.2675C145.57 81.9902 145.57 78.298 143.291 76.0208Z M143.291 30.0208C141.012 27.7435 137.318 27.7435 135.039 30.0208L100.157 64.8819L64.9608 29.7079C62.6821 27.4307 58.9877 27.4307 56.709 29.7079C54.4303 31.9852 54.4303 35.6774 56.709 37.9546L95.4527 76.6744C95.6151 76.8895 95.7943 77.096 95.9905 77.292C98.2691 79.5693 101.964 79.5693 104.242 77.292L143.291 38.2675C145.57 35.9902 145.57 32.298 143.291 30.0208Z",
                "M176.977 60.0704C173.123 56.2163 166.874 56.2163 163.02 60.0703L104.021 119.069L44.4925 59.5409C40.6385 55.6869 34.3898 55.6869 30.5358 59.5409C26.6817 63.3949 26.6817 69.6436 30.5358 73.4976L96.0651 139.027C96.3397 139.391 96.6429 139.741 96.9747 140.072C100.829 143.926 107.077 143.926 110.931 140.072L176.977 74.0271C180.831 70.173 180.831 63.9244 176.977 60.0704Z M176.977 60.0704C173.123 56.2163 166.874 56.2163 163.02 60.0703L104.021 119.069L44.4925 59.5409C40.6385 55.6869 34.3898 55.6869 30.5358 59.5409C26.6817 63.3949 26.6817 69.6436 30.5358 73.4976L96.0651 139.027C96.3397 139.391 96.6429 139.741 96.9747 140.072C100.829 143.926 107.077 143.926 110.931 140.072L176.977 74.0271C180.831 70.173 180.831 63.9244 176.977 60.0704Z M176.977 60.0704C173.123 56.2163 166.874 56.2163 163.02 60.0703L104.021 119.069L44.4925 59.5409C40.6385 55.6869 34.3898 55.6869 30.5358 59.5409C26.6817 63.3949 26.6817 69.6436 30.5358 73.4976L96.0651 139.027C96.3397 139.391 96.6429 139.741 96.9747 140.072C100.829 143.926 107.077 143.926 110.931 140.072L176.977 74.0271C180.831 70.173 180.831 63.9244 176.977 60.0704Z",
                "M30.5358 139.543C34.3898 143.397 40.6385 143.397 44.4925 139.543L103.492 80.5439L163.02 140.072C166.874 143.926 173.123 143.926 176.977 140.072C180.831 136.218 180.831 129.97 176.977 126.116L111.447 60.5863C111.173 60.2221 110.87 59.8727 110.538 59.5409C106.684 55.6869 100.435 55.6869 96.5811 59.5409L30.5358 125.586C26.6818 129.44 26.6818 135.689 30.5358 139.543Z"
            ],
            transition: {
                repeat: Infinity,
                ease: "easeInOut",
                duration: 3,
                delay: .5,
                times: [0, 0.16, 0.33, 0.5, 0.66]
            }
        }
    };

    return (
        <AnimatePresence mode='wait'>
            <motion.svg
                style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '2.8vw'
                }}
                viewBox="0 0 200 200"
                variants={svgProps}
                initial={"hidden"}
                whileInView={"visible"}
            >
                <motion.path
                    style={{
                        stroke: "#FF636D",
                        strokeLinecap: "round",
                        fill: "#FF636D",
                        strokeWidth: 5
                    }}
                    variants={draw}
                />
            </motion.svg>
        </AnimatePresence>

    );
}
export default Chevron;
