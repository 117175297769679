import Text from "@/components/elements/atoms/Text";
import Footer from "@/components/elements/molecules/Footer";
import Navigation from "@/components/elements/molecules/Navigation";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import STYLES from "./TermsOfUse.module.sass";

const TermsOfUse = (): JSX.Element => {
  return (
    <Container maxWidth={false} disableGutters>
      <Navigation showHome={true} subPath={true} />
      <div className={STYLES.mainWrapper}>
        <Grid
          container
          justifyContent={"center"}
          className={STYLES.header}
          component={"header"}
          flexDirection={'column'}
        >

          <Text
            colour="BrandPink100"
            fontFamily="primary"
            text="Terms and conditions"
            align="center"
            variant="h1"
            component="h1"
            size="size55"
          />
          <Text
            colour="BrandLime100"
            fontFamily="secondary"
            text="Last Updated: 03/04/2024"
            align="center"
            variant="body1"
            component="p"
            size="size16"
          />
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          className={STYLES.body}
          component={"header"}
        >
          <Grid xs={12} md={8} container justifyContent={"center"} className={STYLES.content}>
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`Please read these Terms and Conditions ("Terms") carefully before using the website of OffGrid Software S.M.P.C. ("Company," "we," "us," or "our"). By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms, including the privacy policy and any additional terms and conditions that may apply to specific sections of our website. If you do not agree to these Terms, please do not use our website.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`1. Introduction`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <p>
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`1.1. OffGrid Software S.M.P.C. operates the website located at `}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <a href='/' title="Off Grid Software development website">
                <Text
                  colour="BrandPink100"
                  fontFamily="primary"
                  text={`https://thisisoffgrid.com`}
                  align="left"
                  variant="body1"
                  component="span"
                  size="size20"
                />
              </a>

              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={` (the "Website"). These Terms govern your use of our Website and your relationship with OffGrid Software S.M.P.C.`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
            </p>

            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`1.2. If you have any questions or concerns regarding these Terms or our Website, please contact us at legal@thisisoffgrid.com.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`2. Privacy`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`2.1. We respect your privacy and are committed to protecting your personal data. Our collection, use, and disclosure of personal information are governed by our Privacy Policy, which is incorporated by reference into these Terms. By using our Website, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`2.2. We comply with the General Data Protection Regulation (GDPR) and other applicable data protection laws. For more information on how we handle personal data, please review our Privacy Policy.
              Intellectual Property`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`3. Intellectual Property`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`3.1. All intellectual property rights, including copyrights, trademarks, trade names, logos, and other materials displayed on our Website, are the property of OffGrid Software S.M.P.C. or their respective owners. You may not use, reproduce, modify, distribute, or display any of the intellectual property without prior written consent from OffGrid Software S.M.P.C.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`4. User Conduct`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`4.1. You agree to use our Website only for lawful purposes and in a manner that does not infringe upon the rights of others or restrict or inhibit their use and enjoyment of the Website.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`4.2. You may not use our Website:`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <ol>
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To transmit any unlawful, defamatory, obscene, offensive, or otherwise objectionable content.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To engage in any fraudulent, deceptive, or misleading activity.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To transmit any viruses, malware, or other harmful computer code.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To interfere with or disrupt the functionality or security of our Website or the servers and networks connected to it.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To collect or harvest any personally identifiable information from our Website without prior consent.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
            </ol>

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`5. Disclaimer of Warranties`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`5.1. Our Website is provided on an "as-is" and "as available" basis. We make no representations or warranties of any kind, whether express or implied, regarding the accuracy, reliability, or availability of our Website.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`5.2. We do not warrant that our Website will be uninterrupted, error-free, or free of viruses or other harmful components. You agree that your use of our Website is at your own risk.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`6. Limitation of Liability`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`6.1. To the fullest extent permitted by law, OffGrid Software S.M.P.C. and its officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our Website.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`6.2. In no event shall our total liability to you for all damages, losses, or causes of action exceed the amount you have paid, if any, to us for accessing our Website.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`7. Indemnification`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`7.1. You agree to indemnify, defend, and hold harmless OffGrid Software S.M.P.C. and its officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, or expenses arising out of or in connection with your use or misuse of our Website.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`8. Amendments`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`8.1. We reserve the right to modify or amend these Terms at any time without prior notice. Any changes will be effective immediately upon posting the updated Terms on our Website.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`9. Governing Law and Jurisdiction`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`9.1. These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`10. Contact Information`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <p className={STYLES.contactDetails}>
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`OffGrid Software S.M.P.C.`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`30 Kondyli Street`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Glyfada, Athens`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Greece`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Email: legal@thisisoffgrid.com`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
            </p>

            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`By using our Website, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

          </Grid>
        </Grid>
        <Footer subPath={true} />
      </div>
    </Container>
  );
};

export default TermsOfUse;
