import Spaceship from "@/assets/illustrations/spaceship_svg.svg";
import OGButton from "@/components/elements/atoms/OGButton";
import Text from "@/components/elements/atoms/Text";
import Grid from "@mui/material/Unstable_Grid2";
import { motion } from 'framer-motion';
import React, { Fragment } from "react";
import STYLES from "./Contact.module.sass";
const Contact = (): JSX.Element => {
  const variants = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
    hidden: { opacity: 0 },
  }

  return (
    <Fragment>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
      >
        <Grid
          container
          component="section"
          flexDirection="column"
          alignContent="center"
          className={STYLES.section}
        >
          <Grid
            container
            justifyContent={"flex-start"}
            className={STYLES.card}
            component={"div"}
            flexDirection={'row'}
            wrap="nowrap"
            alignItems={'center'}
          >
            <Grid xs={7} className={STYLES.copy}>
              <Text
                colour="BrandGreen300"
                fontFamily="primary"
                text="Craft. Customize. Explore."
                align="left"
                variant="h3"
                component="p"
                size="size55"
              />
              <Text
                colour="BrandGreen300"
                fontFamily="secondary"
                text="Digital wilderness software for limitless possibilities awaits. Get onboard with OffGrid."
                align="left"
                variant="body1"
                component="p"
                size="size28"
              />
              <OGButton href='/#contact'>
                {'Make contact'}
              </OGButton>
            </Grid>
            <Grid xs={5} className={STYLES.images} container flexDirection={'column'}>
              <motion.img
                src={Spaceship}
                className={STYLES.spaceship}
                animate={{
                  translateY: [-120, -180],
                  transition: {
                    duration: 3,
                    ease: 'easeIn',
                    repeat: Infinity,
                    repeatType: "reverse",
                  }
                }}
                alt="Spaceship illustration"
                draggable={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
    </Fragment>
  );
};

export default Contact;
