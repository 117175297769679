import Footer from "@/components/elements/molecules/Footer";
import Preloader from '@/components/elements/molecules/Preloader';
import Contact from "@/components/elements/organisms/Contact";
import FAQs from "@/components/elements/organisms/FAQs";
import HomeHero from "@/components/elements/organisms/HomeHero";
import HowWeWork from "@/components/elements/organisms/HowWeWork";
import Industries from "@/components/elements/organisms/Industries";
import Services from "@/components/elements/organisms/Services";
import Values from "@/components/elements/organisms/Values";
import Container from "@mui/material/Container";
import React from "react";

const Home = (): JSX.Element => (
  // <div style={{ overflowX: "hidden", position: "relative" }}>
  <Container maxWidth={false} disableGutters>
    <Preloader />
    <HomeHero />
    <Services />
    <Industries />
    <Values />
    <Contact />
    <HowWeWork />
    <FAQs />
    <Footer />
  </Container>
  // </div>

);

export default Home;
