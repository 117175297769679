// Pages
import ErrorPage from "@/components/pages/ErrorPage";
import Home from "@/components/pages/Home";
import PrivacyPolicy from "@/components/pages/PrivacyPolicy";
import TermsOfUse from "@/components/pages/TermsOfUse";

import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Lenis from '@studio-freight/lenis';
import ReactDOM from "react-dom/client";
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration } from "react-router-dom";
import "./index.sass";

import React, { useEffect } from "react";
import reportWebVitals from "./reportWebVitals";
const BREAKPOINTS = {
  xs: 320,
  sm: 600,
  md: 834,
  lg: 1280,
  xl: 1280,
};

let theme = createTheme({
  breakpoints: {
    values: BREAKPOINTS,
  },
  typography: {
    // Tell Material UI what the font-size on the html element is.
    fontFamily: ["Manrope", "sans-serif"].join(","),
    htmlFontSize: 10,
    h1: {
      fontSize: "5.5rem",
      fontWeight: 800,
    },
    body1: {
      fontSize: "1.8rem",
      fontWeight: 500,
    },
    body2: {
      fontWeight: 400,
    },
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: false, // No more ripple, on the whole application 💣!
        color: '#2C514C',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: '.6rem .6rem 0 #2C514C',
          transform: 'none',
          borderRadius: '.84vw',
          transition: 'all .2s easeOut',
          [`&:hover`]: {
            backgroundColor: '#E3FDA5',
            boxShadow: '.2rem .2rem 0 #2C514C',
            transform: 'translate(.4rem, .4rem)',
            transformOrigin: 'bottom right'
          },
          [`&:disabled`]: {
            backgroundColor: '#E3FDA5',
            boxShadow: '.2rem .2rem 0 #2C514C',
            transform: 'translate(.4rem, .4rem)',
            transformOrigin: 'bottom right'
          }
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#2C514C'
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          display: "inline-flex",
          transform: "none",
          transition: 'transform .1s linear',
          [`&:hover`]: {
            transform: 'scale(1.1)',
          }
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#2C514C',
        },
        root: {
          background: 'white',
          borderRadius: '.84vw',
          boxShadow: '.6rem .6rem 0px #2C514C',
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '.3rem',
            borderColor: 'var(--TextField-brandBorderFocusedColor)'
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '.3rem',
            borderColor: 'var(--TextField-brandBorderFocusedColor)',
          },
          [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '.3rem',
            borderColor: '#EA8553'
          }
        },
      },
    },
  },
});

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#E3FDA5',
        contrastText: '#2C514C',
      },
      name: 'lime',
    }),
  },
});

const AppLayout = () => {
  // Smooth scrolling
  useEffect(() => {
    const lenis = new Lenis()
    const raf = (time: number) => {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
  })

  // Fix anchor links for Lenis smooth scrolling
  useEffect(() => {
    document.querySelectorAll('a[href^="/#"]').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        const id = el.getAttribute('href')?.slice(2)
        if (!id) return
        const target = document.getElementById(id)
        if (target) {
          target.scrollIntoView({ behavior: 'smooth' })
        }
      })
    })
  })

  return (
    <>
      <Outlet />
      <ScrollRestoration />
    </>
  )
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <ErrorPage />, // Comment out to see actual error in case you want to have customised error messages
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsOfUse />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <RouterProvider router={router} fallbackElement={<ErrorPage />} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
