import Text from "@/components/elements/atoms/Text";
import Grid from "@mui/material/Unstable_Grid2";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import React, { Fragment, useState } from "react";
import STYLES from "./FAQs.module.sass";

// Assets

type AccordionItem = {
  title: string
  content: string
  footnote?: string
}

type AccordionProps = {
  item: AccordionItem
  i: number
  expanded: number | false
  setExpanded: React.Dispatch<React.SetStateAction<number | false>>
}

const accordionItems: AccordionItem[] = [{
  title: "What kind of app ideas can you help me build?",
  content: "We love all app ideas, from the wildly creative to the seemingly simple! Our mobile app development expertise spans next‑gen tech and user‑centric design, so no concept is too big or too small."
},
{
  title: "I have a unique business need. Can you develop custom software for me?",
  content: "Absolutely! OffGrid thrives on crafting custom software solutions. Think of us as your software architects - we'll translate your unique needs into a powerful and efficient software solution."
},
{
  title: "How does OffGrid approach e‑commerce development differently?",
  content: "We go beyond the typical checkout process. Our e‑commerce solutions prioritize user engagement, offering a seamless journey from browsing to purchase. Additionally, we prioritize data security and operational efficiency to fuel your wild growth."
},
{
  title: "What industries does OffGrid have experience in?",
  content: "OffGrid has a successful track record developing solutions for a variety of industries, including retail, B2B e‑commerce, FMCG, advertising, and healthcare. We leverage cutting‑edge tech to solve your industry‑specific challenges and empower your business success."
},
{
  title: `What is the "OffGrid Way" and how does it benefit me?`,
  content: `The OffGrid Way is our unique approach that combines innovation with real‑world impact. We believe disruption fosters progress, and we're passionate about connecting your vision with effective solutions. This translates to software that not only meets your needs but also propels your business forward.`
},
{
  title: `What is the process for working with OffGrid?`,
  content: `We believe in a transparent and collaborative process. We start with a thorough Business & Technical Analysis to understand your challenges and translate them into a clear plan. Our UX/UI design ensures a user‑friendly experience, while our agile development process guarantees frequent testing and adjustments to deliver exactly what you envision. Finally, we provide comprehensive post‑launch support to ensure your software continues to evolve and meet your needs.`
},
{
  title: `Are You Real?`,
  content: `Yes, and no. We're not exactly Bigfoot roaming the digital wilderness, but we're not a bunch of ones and zeroes chilling in a server bank either. Think of us as a pack of highly caffeinated code wranglers who speak fluent human (and a whole lot of caffeine). We use real brains (most of the time) to build the digital ecosystems you need to unleash your wildest ideas.`,
  footnote: `So, are we real in the traditional sense? Debatable. Are we real in the sense that we can turn your vision into a kick‑butt software solution? Absolutely!`
}]

const Accordion = ({ item, i, expanded, setExpanded }: AccordionProps): JSX.Element => {
  const isOpen = i === expanded;
  const variants = {
    active: {
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.5,
        duration: 0.4,
        delay: 0
      }
    },
    inactive: {
      rotate: 90,
      transition: {
        type: "spring",
        bounce: 0.5,
        duration: 0.4,
        delay: 0
      }
    }
  }

  return (
    <>
      <motion.header
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
        className={`${STYLES.accordionTitle} ${isOpen ? STYLES.accordionTitleOpen : STYLES.accordionTitleClosed}`}
      >
        <Text
          colour="BrandGreen300"
          fontFamily="secondary"
          text={item.title}
          component="p"
          bold
          align="left"
          size="size30"
        />
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" className={STYLES.accordionIcon}>
          <rect x="0.146484" y="15.5996" width="32" height="2" fill="#2C514C" />
          <motion.rect
            x="15.1465"
            y="0.599609"
            width="2"
            height="32"
            fill="#2C514C"
            animate={isOpen ? "inactive" : "active"}
            variants={variants}
            className={STYLES.iconLine}
          />
        </svg>
      </motion.header >
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            layout
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ type: "spring", duration: 0.4, bounce: 0.1 }}
          >
            <motion.div
              variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
              transition={{ type: "spring", duration: 0.4, bounce: 0.1 }}
              className={STYLES.accordionContent}
            >
              <Text
                colour="BrandGreen300"
                fontFamily="secondary"
                text={item.content}
                component="p"
                align="left"
                size="size20"
              />
              {
                item.footnote && (
                  <Fragment>
                    <br />
                    <br />
                    <Text
                      colour="BrandGreen300"
                      fontFamily="secondary"
                      text={item.footnote}
                      component="p"
                      align="left"
                      size="size20"
                    />
                  </Fragment>

                )
              }
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

const FAQAccordion = () => {
  const [expanded, setExpanded] = useState<false | number>(false);
  return accordionItems.map((item: AccordionItem, i: number) => (
    <Accordion item={item} i={i} key={i} expanded={expanded} setExpanded={setExpanded} />
  ));
};

const FAQs = (): JSX.Element => {
  return (
    <Fragment>
      <Grid
        container
        justifyContent='space-around'
        className={STYLES.section}
        component={"section"}
        rowSpacing={{ xs: 4, md: 0 }}
      >
        <Grid xs={12} md={4} className={STYLES.faqTitle}>
          <Text
            colour="BrandGreen300"
            fontFamily="primary"
            text="FAQs"
            align="center"
            variant="h3"
            component="p"
            size="size55"
          />
        </Grid>
        <Grid xs={12} md={8}>
          <LayoutGroup>
            <FAQAccordion />
          </LayoutGroup>
        </Grid>
      </Grid>

    </Fragment >
  );
};

export default FAQs;
