import Text from "@/components/elements/atoms/Text";
import Footer from "@/components/elements/molecules/Footer";
import Navigation from "@/components/elements/molecules/Navigation";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import STYLES from "./PrivacyPolicy.module.sass";

const PrivacyPolicy = (): JSX.Element => {
  return (
    <Container maxWidth={false} disableGutters>
      <Navigation showHome={true} subPath={true} />
      <div className={STYLES.mainWrapper}>

        <Grid
          container
          justifyContent={"center"}
          className={STYLES.header}
          component={"header"}
          flexDirection={'column'}
        >
          <Text
            colour="BrandPink100"
            fontFamily="primary"
            text="Privacy policy"
            align="center"
            variant="h1"
            component="h1"
            size="size55"
          />
          <Text
            colour="BrandLime100"
            fontFamily="secondary"
            text="Last Updated: 03/04/2024"
            align="center"
            variant="body1"
            component="p"
            size="size16"
          />
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          className={STYLES.body}
          component={"header"}
        >
          <Grid xs={12} md={8} container justifyContent={"center"} className={STYLES.content}>
            <p>
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`OffGrid Software S.M.P.C. ("Company," "we," "us," or "our") is committed to protecting the privacy and security of your personal data. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our website located at `}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <a href='/' title="Off Grid Software development website">
                <Text
                  colour="BrandPink100"
                  fontFamily="primary"
                  text={`https://thisisoffgrid.com`}
                  align="left"
                  variant="body1"
                  component="span"
                  size="size20"
                />
              </a>
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={` (the "Website"). By accessing or using our Website, you consent to the collection, use, disclosure, and protection of your personal information as described in this Privacy Policy.`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
            </p>

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`1. Information We Collect`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`1.1. Personal Information: We may collect personal information that you voluntarily provide to us when you use our Website, such as your name, email address, phone number, and any other information you choose to provide.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`1.2. Usage Data: We may collect information about how you interact with our Website, including your IP address, browser type, operating system, referring/exit pages, and date/time stamps. We may also collect information about your usage patterns and preferences.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`1.3. Cookies and Similar Technologies: We may use cookies, web beacons, and similar technologies to collect information about your browsing activities and enhance your experience on our Website. You can control the use of cookies through your browser settings.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`2. Use of Information`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`2.1. We may use the information we collect for the following purposes:`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <ol>
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To provide and maintain our Website and services.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To personalize your experience on our Website.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To communicate with you and respond to your inquiries.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To send you promotional materials, newsletters, or other marketing communications.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To improve our Website and develop new features.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To detect, prevent, and address technical issues or fraudulent activities.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`To comply with legal obligations.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
            </ol>

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`3. Disclosure of Information`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`3.1. We may disclose your personal information to the following parties:`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <ol>
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Service Providers: We may share your information with third-party service providers who assist us in operating our Website and providing related services. These service providers have access to your personal information only to perform specific tasks on our behalf and are obligated to protect your information.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Legal Requirements: We may disclose your personal information if required to do so by law or in response to valid legal requests, such as court orders or government regulations.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Protection of Rights: We may disclose your personal information when we believe it is necessary to enforce our Terms and Conditions, protect our rights, or investigate and prevent potential violations.`}
                align="left"
                variant="body1"
                component="li"
                size="size20"
              />
            </ol>

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`4. Data Security`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`4.1. We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`5. Third-Party Websites`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`5.1. Our Website may contain links to third-party websites or services that are not owned or controlled by OffGrid Software S.M.P.C. This Privacy Policy applies only to our Website. We encourage you to review the privacy policies of any third-party websites you visit.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`6. Children's Privacy`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`6.1. Our Website is not intended for children under the age of 16. We do not knowingly collect personal information from children under the age of 16. If you believe we have inadvertently collected information from a child under 16, please contact us immediately, and we will delete the information as soon as possible.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />
            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`7. Your Rights`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`7.1. You have the right to access, correct, update, or delete your personal information. If you would like to exercise any of these rights or have any questions regarding your personal information, please contact us using the contact information provided below.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`8. Changes to this Privacy Policy`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`8.1. We may update this Privacy Policy from time to time. The updated Privacy Policy will be posted on our Website with a revised "Last Updated" date. We encourage you to review this Privacy Policy periodically for any changes.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

            <Text
              colour="BrandLime100"
              fontFamily="primary"
              text={`9. Contact Information`}
              align="center"
              variant="body1"
              component="h3"
              size="size28"
            />
            <p className={STYLES.contactDetails}>
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`OffGrid Software S.M.P.C.`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`30 Kondyli Street`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Glyfada, Athens`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Greece`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
              <Text
                colour="BrandLime100"
                fontFamily="secondary"
                text={`Email: legal@thisisoffgrid.com`}
                align="left"
                variant="body1"
                component="span"
                size="size20"
              />
            </p>

            <Text
              colour="BrandLime100"
              fontFamily="secondary"
              text={`By using our Website, you acknowledge that you have read, understood, and agreed to this Privacy Policy.`}
              align="left"
              variant="body1"
              component="p"
              size="size20"
            />

          </Grid>
        </Grid>
        <Footer subPath={true} />
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
