import Globe from "@/assets/illustrations/globe.svg";
import { ReactComponent as OffGridLogo } from "@/assets/logos/offGridLogo.svg";
import OGButton from "@/components/elements/atoms/OGButton";
import Text from '@/components/elements/atoms/Text';
import Container from "@mui/material/Container";
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from "@mui/material/Unstable_Grid2";
import { motion } from 'framer-motion';
import React, { Fragment, useRef } from "react";
import STYLES from "./Footer.module.sass";
import { useFormControls } from "./FormControls";

type NavItem = {
  txt: string
  href: string
}

const navItems: NavItem[] = [
  { txt: 'Services', href: '/#services' },
  { txt: 'Industries', href: '/#industries' },
  { txt: 'Values', href: '/#values' },
  { txt: 'How we work', href: '/#how-we-work' }
]


const inputFieldValues = [
  {
    name: "from_name",
    placeholder: "Name",
    gridMD: 6,
    gridXS: 12
  },
  {
    name: "from_email",
    placeholder: "Email",
    gridMD: 6,
    gridXS: 12
  },
  {
    name: "message",
    placeholder: "Your message...",
    multiline: true,
    rows: 3,
    gridMD: 12,
    gridXS: 12
  }
];

const Footer = ({
  subPath = false
}: {
  subPath?: boolean
}): JSX.Element | undefined => {
  const formRef = useRef<HTMLFormElement | null>(null)

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    showError,
    showSuccess,
    showForm
  } = useFormControls(formRef as any);
  if (!formRef) return

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        justifyContent={"center"}
        className={STYLES.footer}
        component={"footer"}
        rowSpacing={{ xs: 8, md: 0 }}
      >
        <Grid
          xs={12}
          md={4}
          className={STYLES.columnLinks}
          container
          flexDirection={'column'}
          justifyContent={"center"}
          alignContent={"center"}
          rowSpacing={{ xs: 1, md: 0 }}
        >
          <OffGridLogo fill={'#2C514C'} className={STYLES.logo}
          />
          {
            navItems.map((navItem: NavItem, index: number) => (
              <Fragment key={index}>
                <Link href={subPath ? `.${navItem.href}` : navItem.href} underline="none" key={index}>
                  <Text
                    colour="BrandGreen300"
                    fontFamily="secondary"
                    text={navItem.txt}
                    variant="body1"
                    component="span"
                    size="size16"
                    align="left"
                  />
                </Link>
              </Fragment>

            ))
          }
          <motion.img draggable={false} src={Globe} className={STYLES.globe} alt="Globe illustration" animate={{
            rotateY: [0, 180, 0, 0],
            transition: {
              duration: 1,
              ease: 'easeIn',
              repeat: Infinity,
              repeatType: "reverse",
              repeatDelay: 10
            }
          }} />
        </Grid>
        <Grid xs={12} md={8} container justifyContent={"center"} flexDirection='row' className={STYLES.contactWrapper}>
          <div id='contact' className={STYLES.contactCard}>
            <Text
              colour="BrandLime200"
              fontFamily="secondary"
              text='Get in touch'
              variant="body1"
              component="p"
              size="size30"
              align="center"
              bold
            />
            <Text
              colour="BrandLime200"
              fontFamily="primary"
              text='How can we help?'
              variant="body1"
              component="p"
              size="size55"
              align="center"
            />
            {
              showForm && (
                <form autoComplete="off" ref={formRef} onSubmit={handleFormSubmit}>
                  <Grid
                    container
                    justifyContent={"flex-end"}
                    spacing={3}
                    className={STYLES.formWrapper}
                  >
                    {inputFieldValues.map((inputFieldValue, index) => {
                      return (
                        <Grid xs={inputFieldValue.gridXS} md={inputFieldValue.gridMD} key={index}>
                          <OutlinedInput
                            required
                            key={index}
                            onChange={handleInputValue}
                            onBlur={handleInputValue}
                            name={inputFieldValue.name}
                            placeholder={inputFieldValue.placeholder}
                            error={errors[inputFieldValue.name]}
                            multiline={inputFieldValue.multiline ?? false}
                            fullWidth
                            rows={inputFieldValue.rows ?? 1}
                            autoComplete="none"
                            {...(errors[inputFieldValue.name] && {
                              error: true,
                              helperText: errors[inputFieldValue.name]
                            })}
                          />
                        </Grid>
                      );
                    })}
                    <Grid xs={12} md={6} className={STYLES.btnWrapper}>
                      <OGButton type="submit" disabled={!formIsValid()} fullWidth>
                        {'Make contact'}
                      </OGButton>
                    </Grid>
                  </Grid>
                </form>
              )
            }
            {
              showSuccess && (
                <div className={STYLES.successMsg}>
                  <Text
                    colour="BrandGreen300"
                    fontFamily="secondary"
                    text='Your message just landed. Thank you!'
                    variant="body1"
                    component="p"
                    size="size16"
                    align="center"
                    bold
                  />
                </div>
              )
            }
            {showError &&
              (
                <div className={STYLES.errorMsg}>
                  <Text
                    colour="BrandLime200"
                    fontFamily="secondary"
                    text='Oops! We are flying our spaceship right now. Please try again later.'
                    variant="body1"
                    component="p"
                    size="size16"
                    align="center"
                    bold
                  />
                </div>
              )
            }
          </div>
        </Grid>
        <div className={STYLES.subFooter}>
          <Link href="mailto:hi@thisisoffgrid.com" underline="none">
            <Text
              colour="BrandGreen300"
              fontFamily="secondary"
              text='hi@thisisoffgrid.com'
              variant="body1"
              component="span"
              size="size16"
              align="left"
            />
          </Link>
          <div className={STYLES.links}>
            <Link href='/privacy-policy' underline="none">
              <Text
                colour="BrandGreen300"
                fontFamily="secondary"
                text='Privacy policy'
                variant="body1"
                component="span"
                size="size16"
                align="left"
              />
            </Link>
            <Link href='/terms-and-conditions' underline="none">
              <Text
                colour="BrandGreen300"
                fontFamily="secondary"
                text='Terms and conditions'
                variant="body1"
                component="span"
                size="size16"
                align="left"
              />
            </Link>
          </div>
        </div>
      </Grid>
    </Container >
  );
};

export default Footer;
