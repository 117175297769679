import Text from "@/components/elements/atoms/Text";
import { AnimatePresence, motion } from 'framer-motion';
import React, { Fragment, useEffect, useState } from "react";
import STYLES from "./Preloader.module.sass";
const words = ["Hello", "Bonjour", "Ciao", "Olà", "やあ", "Hallå", "Guten tag", "Hallo", "Γεια"]
const opacity = {
    initial: {
        opacity: 0
    },
    enter: {
        opacity: 0.75,
        transition: { duration: 1, delay: 0.2 }
    },
}

const slideUp = {
    initial: {
        top: 0
    },
    exit: {
        top: "-100vh",
        transition: { duration: 0.8, ease: [0.76, 0, 0.24, 1], delay: 0.2 }
    }
}

const Preloader = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true)
    const [index, setIndex] = useState(0);
    const [dimension, setDimension] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
            document.body.style.cursor = 'default'
            window.scrollTo(0, 0)
        }, 2200);

        return () => {
            clearTimeout(timeout);
        };
    }, []);


    useEffect(() => {
        setDimension({ width: window.innerWidth, height: window.innerHeight })
    }, [])

    useEffect(() => {
        if (index === words.length - 1) return;
        setTimeout(() => {
            setIndex(index + 1)
        }, index === 0 ? 1000 : 150)
    }, [index])
    const initialPath = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width / 2} ${dimension.height + 300} 0 ${dimension.height}  L0 0`
    const targetPath = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width / 2} ${dimension.height} 0 ${dimension.height}  L0 0`

    const curve = {
        initial: {
            d: initialPath,
            transition: { duration: 0.7, ease: [0.76, 0, 0.24, 1] }
        },
        exit: {
            d: targetPath,
            transition: { duration: 0.7, ease: [0.76, 0, 0.24, 1], delay: 0.3 }
        }
    }
    return (
        <Fragment>
            <AnimatePresence mode='wait'>
                {isLoading && <motion.div variants={slideUp} initial="initial" exit="exit" className={STYLES.section}>
                    {dimension.width > 0 &&
                        <>
                            <motion.div variants={opacity} initial="initial" animate="enter">
                                <span></span>
                                <Text
                                    colour="BrandLime100"
                                    fontFamily={index % 2 === 0 ? 'secondary' : 'primary'}
                                    text={words[index]}
                                    align="center"
                                    variant="h1"
                                    component="p"
                                    size="size65"
                                />
                            </motion.div>
                            <svg>
                                <motion.path variants={curve} initial="initial" exit="exit"></motion.path>
                            </svg>
                        </>
                    }
                </motion.div>}
            </AnimatePresence>

        </Fragment>
    );
};

export default Preloader;
