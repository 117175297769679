import Clippy from '@/assets/illustrations/clippy.svg';
import Card from "@/components/elements/atoms/Card";
import Text from "@/components/elements/atoms/Text";
import Grid from "@mui/material/Unstable_Grid2";

import {
  motion,
  useDragControls,
  useScroll
} from "framer-motion";
import React, { Fragment, useRef } from "react";
import STYLES from "./HowWeWork.module.sass";

type CardType = {
  title: string
  description: string
  color: string
}

const cards: CardType[] = [{
  title: "Business & Technical Analysis",
  description: "We turn your business challenges into tech solutions. We'll translate your idea, answer project questions, plan it all out, and estimate time and budget.",
  color: 'BrandGrey'
},
{
  title: "UX/UI Design",
  description: "UX/UI Design crafts user‑friendly software. We focus on clear navigation, easy information access, and a beautiful design that works on any device. Together, let's create a simply sophisticated experience!",
  color: 'BrandGrey'
},
{
  title: "Front‑end & Back‑end Development",
  description: "We build beautiful user interfaces (front‑end) and powerful engines (back‑end) for your web or app idea. Our agile process ensures frequent testing and adjustments so you get exactly what you want.",
  color: 'BrandGrey'
},
{
  title: "Quality Assurance",
  description: "We make sure software is high quality before it's released by testing it against strict standards. We're the quality guardians!",
  color: 'BrandGrey'
},
{
  title: "User Acceptance Testing (UAT)",
  description: "User testing (UAT) is the final check before launch. Real people try out the software to ensure it works for everyday tasks, meeting customer needs. Real issues for real people.",
  color: 'BrandGrey'
},
{
  title: "Post Launch Support",
  description: "The launch is just the start of your software's journey. We're here for the long haul, ensuring you get the most out of it through ongoing support and improvements",
  color: "BrandGrey"
}]

const HowWeWork = (): JSX.Element => {
  const controls = useDragControls()
  const sectionRef = useRef(null)
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end']
  })

  return (
    <Fragment>
      <Grid
        container
        justifyContent="center"
        className={STYLES.section}
        component="section"
        flexDirection='row'
        alignItems='center'
        ref={sectionRef}
        id={'how-we-work'}
      >
        <Grid
          container
          justifyContent="flex-start"
          component="section"
          flexDirection='column'
          alignItems='center'
        >
          <Text
            colour="BrandLime200"
            fontFamily="secondary"
            text="The OffGrid Way"
            align="center"
            variant="h3"
            component="p"
            size="size30"
            bold
          />
          <Text
            colour="BrandLime200"
            fontFamily="primary"
            text="How we work"
            align="center"
            variant="h3"
            component="p"
            size="size55"
          />
          <motion.div drag dragElastic={0.8} dragMomentum={false} dragPropagation whileDrag={{ scale: 1.1 }} dragControls={controls} dragConstraints={sectionRef} className={STYLES.clippyWrapper} >
            <img src={Clippy} alt={''} className={STYLES.clippy} draggable={false} />
          </motion.div>
          <main ref={container} className={STYLES.main}>
            {
              cards.map((card, i) => {
                const targetScale = 1 - ((cards.length - i) * 0.05);
                return <Card key={`p_${i}`} i={i} {...card} progress={scrollYProgress} range={[i * .20, 1]} targetScale={targetScale} />
              })
            }
          </main>
        </Grid>
      </Grid>
    </Fragment >
  )
}

export default HowWeWork