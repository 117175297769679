import Text from "@/components/elements/atoms/Text";
import Grid from "@mui/material/Unstable_Grid2";
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from "react";
import STYLES from "./Values.module.sass";
const Values = (): JSX.Element => {

  const tileVariants = {
    offscreen: {
      y: '-100vh',
      opacity: 0,
      rotate: 40,
    },
    onscreen: {
      y: -5,
      opacity: 1,
      rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 1.4,
        delay: 0
      }
    }
  }

  const cn = classNames(STYLES.section, STYLES.universeBg);

  return (
    <Grid
      container
      justifyContent="flex-start"
      className={cn}
      component="section"
      flexDirection='column'
      alignItems='center'
      id={'values'}
    >
      <div className={STYLES.paddingWrap}>
        <Text
          colour="BrandLime200"
          fontFamily="secondary"
          text="The OffGrid Values"
          variant="h3"
          component="p"
          size="size30"
          bold
        />
        <Text
          colour="BrandLime200"
          fontFamily="primary"
          text="Where Innovation and Impact Collide"
          variant="h3"
          component="p"
          size="size55"
          extraClasses={STYLES.containedCopy}
        />
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          className={STYLES.tilesWrapper}
        >
          <Grid
            container
            justifyContent="center"
            className={STYLES.tiles}
            component="section"
            alignItems='center'
          >
            <motion.div variants={tileVariants}>
              <Text
                colour="White"
                fontFamily="primary"
                text="Quality & Excellence"
                size="size46"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandYelowBase}`]}
              />
            </motion.div>
            <motion.div variants={{
              offscreen: {
                ...tileVariants.offscreen
              },
              onscreen: {
                ...tileVariants.onscreen,
                rotate: 10,
                transition: {
                  ...tileVariants.onscreen.transition,
                  delay: .1
                }
              }
            }}>
              <Text
                colour="BrandGreen300"
                fontFamily="secondary"
                text="Integrity"
                size="size40"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandLime200}`]}
              />
            </motion.div>
            <motion.div variants={{
              offscreen: {
                ...tileVariants.offscreen,
                rotate: 30,
              },
              onscreen: {
                ...tileVariants.onscreen,
                rotate: 0,
                transition: {
                  ...tileVariants.onscreen.transition,
                  delay: .3
                }
              }
            }}>
              <Text
                colour="BrandYellowBase"
                fontFamily="secondary"
                text="Exploration & Learning"
                size="size40"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandGreen200} ${STYLES.extraSpaceSeven}`]}
              />
            </motion.div>
            <motion.div variants={{
              offscreen: {
                ...tileVariants.offscreen,
                rotate: 30,
              },
              onscreen: {
                ...tileVariants.onscreen,
                rotate: 5,
                y: -5,
                transition: {
                  ...tileVariants.onscreen.transition,
                  delay: .6
                }
              }
            }}>
              <Text
                colour="BrandYellowBase"
                fontFamily="secondary"
                text="Remote Work Revolution"
                size="size40"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandGreen300} ${STYLES.extraSpaceThree}`]}
              />
            </motion.div>
            <motion.div variants={{
              offscreen: {
                ...tileVariants.offscreen,
                rotate: 30,
              },
              onscreen: {
                ...tileVariants.onscreen,
                rotate: -10,
                transition: {
                  ...tileVariants.onscreen.transition,
                  delay: .5
                }
              }
            }}>
              <Text
                colour="BrandGreen200"
                fontFamily="primary"
                text="Thought Leadership"
                size="size46"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandPink100}`]}
              />
            </motion.div>
            <motion.div variants={{
              offscreen: {
                ...tileVariants.offscreen,
                rotate: -30,
              },
              onscreen: {
                ...tileVariants.onscreen,
                rotate: -5,
                y: 15,
                transition: {
                  ...tileVariants.onscreen.transition,
                  delay: .7
                }
              }
            }}>
              <Text
                colour="BrandYellowBase"
                fontFamily="secondary"
                text="Customer Centricity"
                size="size40"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandGreen200}`]}
              />
            </motion.div>
            <motion.div variants={{
              offscreen: {
                ...tileVariants.offscreen,
                rotate: -50,
              },
              onscreen: {
                ...tileVariants.onscreen,
                rotate: 3,
                y: 25,
                transition: {
                  ...tileVariants.onscreen.transition,
                  delay: .4
                }
              }
            }}>
              <Text
                colour="BrandYellowBase"
                fontFamily="primary"
                text="Sustainable Partnerships"
                size="size46"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandWatermelon}`]}
              />
            </motion.div>
            <motion.div variants={{
              offscreen: {
                ...tileVariants.offscreen,
                rotate: 30,
              },
              onscreen: {
                ...tileVariants.onscreen,
                rotate: 0,
                y: 30,
                transition: {
                  ...tileVariants.onscreen.transition,
                  delay: 1
                }
              }
            }}>
              <Text
                colour="BrandGreen300"
                fontFamily="primary"
                text="Agility and Adaptability"
                size="size46"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandLime200} ${STYLES.extraSpaceNegSeven}`]}
              />
            </motion.div>
            <motion.div variants={{
              offscreen: {
                ...tileVariants.offscreen,
                rotate: 30,
              },
              onscreen: {
                ...tileVariants.onscreen,
                rotate: 0,
                y: 30,
                transition: {
                  ...tileVariants.onscreen.transition,
                  delay: .8
                }
              }
            }}>
              <Text
                colour="White"
                fontFamily="primary"
                text="Diversity and Inclustion"
                size="size46"
                extraClasses={[`${STYLES.tile} ${STYLES.BrandYelowBase}`]}
              />
            </motion.div>
          </Grid>
        </motion.div >
      </div>
    </Grid>
  );
};

export default Values;
