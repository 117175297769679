import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const Hexagon = (): JSX.Element => {
  const svgProps = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: .2
      }
    }
  };
  const draw = {
    hidden: { opacity: 1 },
    visible: {
      d: [
        "M162.5 65L100 29L37.5 65L100 101L162.5 65Z M100.5 101L38 65V136.5L100.5 172.5V101Z M162.5 65L100 101V172.5L162.5 137V65Z",
        "M162 55L99.5 19L37 55L99.5 91L162 55Z M90 110.5L27.5 74.5V146L90 182V110.5Z M172.5 74.5L110 110.5V182L172.5 146.5V74.5Z",
        "M162.5 65L100 29L37.5 65L100 101L162.5 65Z M100.5 101L38 65V136.5L100.5 172.5V101Z M162.5 65L100 101V172.5L162.5 137V65Z",
        "M123 83L99 69L75 83L99 97L123 83Z M95 103.73L72 90V117.27L95 131V103.73Z M127 90L103 103.73V131L127 117.46V90Z",
        "M162 55L99.5 19L37 55L99.5 91L162 55Z M90 110.5L27.5 74.5V146L90 182V110.5Z M172.5 74.5L110 110.5V182L172.5 146.5V74.5Z",
        "M162.5 65L100 29L37.5 65L100 101L162.5 65Z M100.5 101L38 65V136.5L100.5 172.5V101Z M162.5 65L100 101V172.5L162.5 137V65Z"
      ],
      transition: {
        repeat: Infinity,
        ease: "easeInOut",
        duration: 3,
        delay: .5,
        times: [0, 0.11, 0.22, 0.33, 0.5, 0.66]
      }
    }
  }

  return (
    <AnimatePresence mode='wait'>
      <motion.svg
        style={{
          width: '100%',
          height: '100%',
          fill: "transparent"
        }}
        viewBox="0 0 200 200"
        variants={svgProps}
        initial={"hidden"}
        whileInView={"visible"}
      >
        <motion.path
          style={{
            fill: "#FFB6CB",
            stroke: "#FFB6CB",
            transformBox: "fill-box",
            strokeLinejoin: "round",
            strokeWidth: 10,
            transformOrigin: "center"
          }}
          variants={draw}
        />
      </motion.svg>
    </AnimatePresence>
  );
}
export default Hexagon;
