// Components
import Text from "@/components/elements/atoms/Text";
import Chevron from "@/components/elements/molecules/Chevron/Chevron";
import Circle from "@/components/elements/molecules/Circle/Circle";
import Hexagon from "@/components/elements/molecules/Hexagon/Hexagon";
import Line from "@/components/elements/molecules/Line/Line";
import Plus from "@/components/elements/molecules/Plus/Plus";
import Grid from "@mui/material/Unstable_Grid2";
// import { Autoplay } from 'swiper/modules';
import {
  Swiper,
  SwiperRef,
  SwiperSlide
} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Libs
import { motion } from 'framer-motion';
import React, { Fragment, useEffect, useRef, useState } from "react";

// Styles
import STYLES from "./Industries.module.sass";

type IndustryCard = {
  title: string
  content: string
  backgroundColour: string
  textColour: string
  svg: JSX.Element
}

const industryCards: IndustryCard[] = [{
  title: "Healthcare",
  content: "We collaborate with healthcare providers to design high‑performing software, optimizing operations and empowering exceptional care.",
  backgroundColour: 'BrandLime100',
  textColour: 'BrandGreen250',
  svg: <Plus />
},
{
  title: "Retail",
  content: "We  equip retailers to navigate the  ever‑changing e‑commerce landscape with seamless customer journeys and next‑gen tech that optimizes operations and secures data, for wild growth.",
  backgroundColour: 'BrandGreen250',
  textColour: 'BrandYellowBase',
  svg: <Circle />
},
{
  title: "B2B Ecommerce",
  content: "We empower B2B retailers by creating robust technology that streamlines complex processes for both buyers and sellers. Thrive against competition with the wildest technology designed to fuel your business success.",
  backgroundColour: 'BrandYellowBase',
  textColour: 'BrandGreen250',
  svg: <Line />
},
{
  title: "Advertising",
  content: "We design interactive, targeted, and innovative experiences that leverage valuable data insights, and we craft modern advertising stories that grab consumer attention in today's advertising environment.",
  backgroundColour: 'BrandPink100',
  textColour: 'BrandGreen200',
  svg: <Hexagon />
},
{
  title: "FMCG",
  content: "We leverage cutting edge tech to unlock deep consumer insights, fueling optimized production, marketing, and product development for more efficient customer-centric operations. We know how to, and we have proved it.",
  backgroundColour: 'BrandWatermelonBase',
  textColour: 'BrandPink100',
  svg: <Chevron />
}]

const card = {
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren"
    }
  }
}
const header = {
  hidden: { opacity: 0, transform: "translateY(100%)" },
  show: { opacity: 1, transform: "translateY(0)" }
}
const copy = {
  hidden: { opacity: 0, transform: "translateY(-100%)" },
  show: { opacity: 1, transform: "translateY(0)" }
}

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
};

const Card = ({
  title,
  content,
  backgroundColour,
  textColour,
  svg,
  expanded,
  onClick,
  // isActive
}: IndustryCard & {
  expanded: boolean
  onClick: () => void
  // isActive: boolean
}): JSX.Element => {
  return (
    <div className={`${STYLES.card} ${STYLES[backgroundColour]} ${expanded ? STYLES.expanded : ""}`} onClick={onClick}>
      {
        expanded ? (
          <motion.div className={STYLES.container} layout variants={card} initial="hidden" whileInView="show" transition={spring} viewport={{ once: true }}>
            <motion.div className={STYLES.cardTitle} layout variants={header}>
              <Text
                colour={textColour}
                fontFamily="primary"
                text={title}
                align="left"
                variant="h3"
                component="p"
                size="size50"
              />
            </motion.div>
            <motion.div className={STYLES.cardCopy} layout variants={copy}>
              <Text
                colour={textColour}
                fontFamily="secondary"
                text={content}
                align="left"
                variant="h3"
                component="p"
                size="size28"
              />
            </motion.div>
          </motion.div>
        ) : (
          svg
        )
      }
    </div>
  )
}

const Industries = (): JSX.Element => {
  const [expanded, setExpanded] = useState<false | number>(0)
  const [initialised, setInitialised] = useState<boolean>(false)
  // const swiperSlide = useSwiperSlide()
  const sliderRef = useRef<SwiperRef | null>(null)

  useEffect(() => {
    const swiper = sliderRef.current?.swiper
    if (
      swiper &&
      !initialised
    ) {
      setInitialised(true)
    }
  }, [initialised])
  return (
    <Fragment>
      <Grid
        container
        justifyContent="flex-start"
        className={STYLES.section}
        component="section"
        flexDirection='column'
        alignItems='center'
        alignContent='center'
        id={'industries'}
      >
        <Text
          colour="BrandLime200"
          fontFamily="secondary"
          text="The OffGrid Industries"
          align="center"
          variant="h3"
          component="p"
          size="size30"
          bold
        />
        <Text
          colour="BrandLime200"
          fontFamily="primary"
          text="Optimized solutions, maximized results."
          align="center"
          variant="h3"
          component="p"
          size="size55"
          extraClasses={STYLES.containedCopy}
        />
        <Swiper
          ref={sliderRef}
          slidesPerView={'auto'}
          spaceBetween={22}
          // centeredSlides={true}
          // autoplay={{
          //   delay: 0,
          //   disableOnInteraction: true,
          // }}
          // navigation={true}
          // loop={true}
          // speed={3000}
          // modules={[Autoplay]}
          slidesOffsetBefore={30}
          slidesOffsetAfter={30}
          grabCursor={true}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={(swiper) => setExpanded(swiper.activeIndex)}
        // onSwiper={(swiper) => setExpanded(swiper.activeIndex)}
        // onActiveIndexChange={(swiper) => setExpanded(swiper.activeIndex)}
        // onSnapGridLengthChange={(swiper) => setExpanded(swiper.activeIndex)}
        >
          {
            initialised && (industryCards.map((item: IndustryCard, i: number) => (
              <SwiperSlide key={i}>
                <Card
                  // isActive={swiperSlide && swiperSlide.isActive}
                  {...item}
                  onClick={() => setExpanded(expanded === i ? false : i)}
                  // onClick={() => setExpanded(i)}
                  expanded={i === expanded}
                />
              </SwiperSlide>
            ))
            )}
        </Swiper>
      </Grid>
    </Fragment >
  );
};

export default Industries;
