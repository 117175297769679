// Components
import Text from '@/components/elements/atoms/Text';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Unstable_Grid2';

// React
import { ReactComponent as OffGridLogo } from "@/assets/logos/offGridLogo.svg";
import React, { Fragment } from 'react';

// Styles
import STYLES from './Navigation.module.sass';

type NavItem = {
  txt: string
  href: string
}

const navItems: NavItem[] = [
  { txt: 'Services', href: '/#services' },
  { txt: 'Industries', href: '/#industries' },
  { txt: 'Values', href: '/#values' },
  { txt: 'How we work', href: '/#how-we-work' },
  { txt: 'Contact', href: '/#contact' }
]

const Navigation = ({
  showHome = false,
  subPath = false
}: {
  showHome?: boolean,
  subPath?: boolean
}): JSX.Element => {
  return (
    <Grid container className={STYLES.sticky} alignItems={'center'} justifyContent={'space-between'}>
      <header className={STYLES.header}>
        {
          subPath ?
            <Link href={'/'}>
              <OffGridLogo fill={'#E3FDA5'} className={STYLES.logo}
              />
            </Link> : <OffGridLogo fill={'#E3FDA5'} className={STYLES.logo}
            />
        }
        <Grid
          container
          justifyContent={"center"}
          className={STYLES.nav}
          component={"nav"}
          spacing={2}
        >

          {
            showHome && (
              <Grid>
                <Fragment>
                  <Link href={'/'} underline="none">
                    <Text
                      colour="BrandLime200"
                      fontFamily="secondary"
                      text='Home'
                      variant="body1"
                      component="span"
                      size="size16"
                      align="left"
                      bold
                    /></Link>
                </Fragment>
              </Grid>
            )
          }
          {
            navItems.map((navItem: NavItem, index: number) => (
              <Grid key={index}>
                <Fragment>
                  <Link href={subPath && navItem.href !== '/#contact' ? `.${navItem.href}` : navItem.href} underline="none">
                    <Text
                      colour="BrandLime200"
                      fontFamily="secondary"
                      text={navItem.txt}
                      variant="body1"
                      component="span"
                      size="size16"
                      align="left"
                      bold
                    />
                  </Link>
                </Fragment>
              </Grid>
            ))
          }
        </Grid>
      </header>
    </Grid>
  );
};

export default Navigation;
