import emailjs from '@emailjs/browser';
import { useState } from "react";

const PostContactForm = async (
    values: any,
    successCallback: any,
    errorCallback: any
) => {
    // do stuff
    // if successful
    if (true) successCallback();
    else { }
};

const initialFormValues = {
    from_name: "",
    from_email: "",
    message: "",
    formSubmitted: false,
    success: false
};

export const useFormControls = (formRef: HTMLFormElement) => {
    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({} as any);
    const [showForm, setShowForm] = useState(true)
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const validate: any = (fieldValues = values) => {
        let temp: any = { ...errors };

        if ("from_name" in fieldValues)
            temp.from_name = fieldValues.from_name ? "" : "This field is required.";

        if ("from_email" in fieldValues) {
            temp.from_email = fieldValues.from_email ? "" : "This field is required.";
            if (fieldValues.from_email)
                temp.from_email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.from_email)
                    ? ""
                    : "Email is not valid.";
        }

        if ("message" in fieldValues)
            temp.message =
                fieldValues.message.length !== 0 ? "" : "This field is required.";

        setErrors({
            ...temp
        });
    };

    const handleInputValue = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        validate({ [name]: value });
    };

    const handleSuccess = () => {
        setValues({
            ...initialFormValues,
            formSubmitted: true,
            success: true
        });
        sendEmail()
    };

    const handleError = () => {
        setValues({
            ...initialFormValues,
            formSubmitted: true,
            success: false
        });
    };

    const formIsValid = (fieldValues = values) => {
        const isValid =
            fieldValues.from_name &&
            fieldValues.from_email &&
            fieldValues.message &&
            Object.values(errors).every((x) => x === "");

        return isValid;
    };


    const sendEmail = (): void => {
        // e.preventDefault();
        // if (!formRef) console.log('no form found!')
        emailjs
            .sendForm('service_aoyg7rk', 'template_s92dhqb', formRef.current!, {
                publicKey: '8jCliX0gBWg2KR89t',
            })
            .then(
                () => {
                    setShowError(false)
                    setShowSuccess(true)
                    setShowForm(false)
                },
                (error) => {
                    setShowForm(true)
                    setShowError(true)
                    setShowSuccess(false)
                    console.log('FAILED...', error.text)
                },
            );
    };

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        const isValid =
            Object.values(errors).every((x) => x === "") && formIsValid();
        if (isValid) {
            await PostContactForm(values, handleSuccess, handleError);
            // setShowError(false)
            // setShowSuccess(true)
            // setShowForm(false)
        }
        // else {
        //     setShowError(true)
        //     setShowSuccess(false)
        // }
    };

    return {
        values,
        errors,
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        showError,
        showSuccess,
        showForm
    };
};
