import classNames from 'classnames';
import { motion, useDragControls } from 'framer-motion';
import React, { Fragment, RefObject, useCallback, useRef, useState } from 'react';

// Assets
import Clock from '@/assets/illustrations/clock.svg';
import Earth from '@/assets/illustrations/earth.svg';
import ECommerce from '@/assets/illustrations/ecommerce.svg';
import Keyboard from '@/assets/illustrations/keyboard.svg';
import Mobile from '@/assets/illustrations/mobile.svg';

// Components
import Text from '@/components/elements/atoms/Text';
import Grid from '@mui/material/Unstable_Grid2';

// Hooks

// STYLES
import STYLES from './Services.module.sass';

type ServiceCardType = {
  title: string
  subtitle: string
  svgIcon: string
  svgAlt: string
  svgClass: string
  backgroundColour: string
  textColour: string
  dragConstraints: { top?: number, left?: number, right?: number, bottom?: number }
  cardZIndex: number
}


type ServiceCardProps = ServiceCardType & {
  zIndexRef: RefObject<number>
  incrementZIndex: () => void
}

const cards = [{
  title: "Custom Software Development",
  subtitle: "Don't just dream it, code it. We're the custom software architects you've been waiting for.",
  svgIcon: Keyboard,
  svgClass: 'keyboard',
  svgAlt: "Keyboard illustration",
  backgroundColour: 'BrandLime200',
  textColour: "BrandGreen200",
  dragConstraints: { left: -120, right: 200 },
  cardZIndex: 2
},
{
  title: "Mobile App Development",
  subtitle: "We're mobile frontiers‑people. No app idea is too wild, or too simple. We build with next‑gen tech, pushing the boundaries of what's possible.",
  svgIcon: Mobile,
  svgClass: 'mobile',
  svgAlt: "Mobile illustration",
  backgroundColour: 'BrandPink100',
  textColour: "BrandGreen200",
  dragConstraints: { left: -320, right: 600 },
  cardZIndex: 1
},
{
  title: "Web Development",
  subtitle: "We don't just code websites, we craft digital jungles. Fluent in creativity and cutting‑edge tech, we'll build an experience that sets your brand free.",
  svgIcon: Earth,
  svgClass: 'earth',
  svgAlt: "Earth illustration",
  backgroundColour: 'BrandYellowBase',
  textColour: "White",
  dragConstraints: { left: -420, right: 200 },
  cardZIndex: 2
},
{
  title: "Technology consulting",
  subtitle: "Future‑proof your business. We're your digital navigator. Technology consulting for a competitive edge.",
  svgIcon: Clock,
  svgClass: 'clock',
  svgAlt: "Clock illustration",
  backgroundColour: 'BrandGreen300',
  textColour: "White",
  dragConstraints: { left: -420, right: 200 },
  cardZIndex: 1
},
{
  title: "E‑commerce Solutions",
  subtitle: "Beyond tedious checkout. More than untamed selection. We craft seamless e‑commerce journeys that keep your customers engaged from click to purchase.",
  svgIcon: ECommerce,
  svgClass: 'ecommerce',
  svgAlt: "Shopping bag illustration",
  backgroundColour: 'BrandGreen200',
  textColour: "White",
  dragConstraints: { left: -520, right: 200 },
  cardZIndex: 1
}]

const ServiceCard = ({
  title,
  subtitle,
  svgIcon,
  svgAlt,
  svgClass,
  backgroundColour,
  textColour,
  dragConstraints,
  zIndexRef,
  incrementZIndex,
  cardZIndex
}: ServiceCardProps): JSX.Element => {
  const cardRef = useRef(null)
  const controls = useDragControls()
  const [isDragging, setDragging] = useState(false)
  const [firstCardDragged, setFirstCardDragged] = useState(false)
  const cn = classNames(STYLES.serviceCard, {
    [STYLES[backgroundColour]]: backgroundColour,
  });

  const svgCn = classNames(STYLES.cardIllustration, {
    [STYLES[svgClass]]: svgClass,
  });

  const handleDragStart = useCallback(() => {
    setFirstCardDragged(true)
    setDragging(true)
    incrementZIndex()
  }, [incrementZIndex]);

  const handleDragEnd = useCallback(() => {
    setDragging(false)
  }, []);

  const ZIndexValue = firstCardDragged ? zIndexRef.current : cardZIndex

  return (
    <motion.div
      drag
      dragElastic={0}
      // dragElastic={0.8}
      dragMomentum={false}
      // dragPropagation={false}
      // whileDrag={{ scale: 1.1, zIndex }}
      dragConstraints={dragConstraints}
      dragControls={controls}
      className={cn}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 1.12 }}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      ref={cardRef}
      style={{ zIndex: ZIndexValue as number }}
    >
      <Text
        colour={textColour}
        fontFamily="primary"
        text="Services"
        align="center"
        variant="h1"
        component="h2"
        size="size30"
      />
      <div className={STYLES.cardContent}>
        <Text
          colour={textColour}
          fontFamily="primary"
          text={title}
          align="center"
          variant="h1"
          component="p"
          size="size55"
        />
        <Text
          colour={textColour}
          fontFamily="secondary"
          text={subtitle}
          align="center"
          variant="h1"
          component="p"
          size="size16"
        />
      </div>
      <img src={svgIcon} alt={svgAlt} className={svgCn} draggable={false} />
    </motion.div >
  );
};

const Services = (): JSX.Element => {
  const sectionRef = useRef(null)
  const zIndexRef = useRef(cards.length)
  const incrementZIndex = () => {
    zIndexRef.current += 1;
  }

  return (
    <Fragment>
      <Grid
        container
        justifyContent={"flex-start"}
        className={STYLES.section}
        component={"section"}
        id="services"
        wrap="nowrap"
        flexDirection='column'
        ref={sectionRef}
      >
        <div className={STYLES.cardsWrapper}>
          {
            cards.map((card: ServiceCardType, index: number) => (
              <ServiceCard
                key={`card-${index}`}
                title={card.title}
                subtitle={card.subtitle}
                svgIcon={card.svgIcon}
                svgAlt={card.svgAlt}
                svgClass={card.svgClass}
                backgroundColour={card.backgroundColour}
                textColour={card.textColour}
                dragConstraints={card.dragConstraints}
                zIndexRef={zIndexRef}
                incrementZIndex={incrementZIndex}
                cardZIndex={card.cardZIndex}
              />
            ))
          }
        </div>
      </Grid>
    </Fragment >
  );
};

export default Services;
