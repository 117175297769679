import Typography, { TypographyProps } from '@mui/material/Typography';
import classNames from 'classnames';
import React from 'react';
import STYLES from './Text.module.sass';

type Props = {
  colour: string
  fontFamily?: 'primary' | 'secondary'
  text: string
  variant?: TypographyProps['variant']
  component?: TypographyProps['component']
  align?: TypographyProps['align']
  extraClasses?: string[] | ""
  size?: string
  bold?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const Text = ({
  colour,
  fontFamily = "primary",
  text,
  variant = "body1",
  component = "p",
  align = "center",
  extraClasses = "",
  size = "size16",
  bold = false,
  onClick
}: Props) => {
  const cn = classNames(STYLES.text, extraClasses, {
    [STYLES[colour]]: colour,
    [STYLES[fontFamily]]: fontFamily,
    [STYLES[size]]: size,
    [STYLES.bold]: bold,
  });

  return (
    <Typography
      variant={variant}
      component={component}
      align={align}
      className={cn}
      onClick={onClick}
    >
      {text}
    </Typography>
  );
};

export default Text;
