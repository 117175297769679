import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const Line = (): JSX.Element => {
  const svgProps = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: .2
      }
    }
  };
  const draw = {
    hidden: { opacity: 1 },
    visible: {
      d: [
        "M 100,30 S 100,100 100,170",
        "M 100,30 S 30,100 100,170",
        "M 100,30 S 170,100 100,170",
        "M 100,30 S 70,100 100,170",
        "M 100,30 S 130,100 100,170",
        "M 100,30 S 100,100 100,170",
      ],
      transition: {
        repeat: Infinity,
        ease: "easeInOut",
        duration: 3,
        delay: .5,
        times: [0, 0.11, 0.22, 0.33, 0.5, 0.66]
      }
    }
  };

  return (
    <AnimatePresence mode='wait'>
      <motion.svg
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '2.8vw'
        }}
        viewBox="0 0 200 200"
        variants={svgProps}
        initial={"hidden"}
        whileInView={"visible"}
      >
        <motion.path
          style={{
            stroke: "#FFBA23",
            strokeLinecap: "round",
            fill: "transparent",
            strokeWidth: 30
          }}
          variants={draw}
        />
      </motion.svg>
    </AnimatePresence>
  );
}
export default Line;
