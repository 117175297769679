import Text from "@/components/elements/atoms/Text";
import Navigation from "@/components/elements/molecules/Navigation";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import STYLES from "./ErrorPage.module.sass";

<Navigation showHome={true} subPath={true} />
const ErrorPage = (): JSX.Element => {
  return (
    <Container maxWidth={false} disableGutters>
      <Navigation showHome={true} subPath={true} />
      <Grid
        container
        justifyContent={"center"}
        className={STYLES.header}
        component={"header"}
      >
        <Text
          colour="BrandLime100"
          fontFamily="secondary"
          text="404"
          align="center"
          component="p"
          size="size65"
          bold
        />
      </Grid>
    </Container>
  );
};

export default ErrorPage;
