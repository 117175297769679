import Button, { ButtonProps } from "@mui/material/Button";
import React from "react";

const OGButton = (props: ButtonProps) => {
  return (
    <Button {...props} size="large" variant='contained' color="primary" />
  );
};

export default OGButton;
