import classNames from 'classnames';
import { MotionValue, motion, useTransform } from 'framer-motion';
import React, { useRef } from 'react';
import STYLES from './Card.module.sass';

// Components
import Text from "@/components/elements/atoms/Text";

type CardProps = {
    i: number
    title: string
    description: string
    color: string
    progress: MotionValue<number>
    range: number[]
    targetScale: number
}

const Card = ({
    i,
    title,
    description,
    color,
    progress,
    range,
    targetScale
}: CardProps): JSX.Element => {
    const container = useRef(null);
    const numberI = (i + 1).toString()
    const scale = useTransform(progress, range, [1, targetScale]);
    const cn = classNames(STYLES.card, {
        [STYLES[color]]: color
    });
    return (
        <div ref={container} className={STYLES.cardContainer}>
            <motion.div
                style={{ scale, top: `calc(-5vh + ${i * 25}px)` }}
                className={cn}
            >
                <div className={STYLES.cardTtile} >
                    <Text
                        colour="BrandGreen300"
                        fontFamily="primary"
                        text={`${numberI}.`}
                        variant="h3"
                        component="p"
                        align='left'
                        size="size55"
                    />
                    <Text
                        colour="BrandGreen300"
                        fontFamily="primary"
                        text={title}
                        variant="h3"
                        component="p"
                        align='left'
                        size="size55"
                    />
                </div>
                <div>
                    <Text
                        colour="BrandGreen300"
                        fontFamily="secondary"
                        text={description}
                        align="left"
                        variant="body1"
                        component="p"
                        size="size28"
                    />
                </div>
            </motion.div>
        </div>
    )
}

export default Card