// Components
import Text from "@/components/elements/atoms/Text";
import Navigation from "@/components/elements/molecules/Navigation";
import Popper from '@mui/material/Popper';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

// Libs
import { AnimatePresence, motion } from "framer-motion";
import React, { Fragment, useState } from "react";

// STYLES
import STYLES from "./HomeHero.module.sass";

const HomeHero = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
  }

  return (
    <Fragment>
      <Navigation />
      <Grid
        container
        justifyContent={"center"}
        className={STYLES.hero}
        component={"section"}
        direction={"column"}
      >
        <Grid className={STYLES.heroCopy}>
          <Grid>
            <Typography
              variant="h1"
              align="center"
              style={{ maxWidth: "70%", margin: "0 auto" }}
            >
              <Text
                colour="BrandPink100"
                fontFamily="secondary"
                text="Where code meets "
                align="center"
                variant="h1"
                component="span"
                size="size55"
              />
              <Text
                colour="BrandBlue100"
                text="untamed creativity"
                align="center"
                variant="h1"
                component="span"
                size="size55"
              />
              <Text
                colour="BrandPink100"
                fontFamily="secondary"
                text="."
                align="center"
                variant="h1"
                component="span"
                size="size55"
              />
              <br />
              <Text
                colour="BrandPink100"
                fontFamily="secondary"
                text="We build ecosystems that embrace a"
                align="center"
                variant="h1"
                component="span"
                size="size55"
              />
            </Typography>
            <Typography
              variant="h1"
              align="center"
              style={{ position: "relative" }}
            >
              <Text
                colour="BrandLime200"
                text="digital wilderness"
                align="center"
                variant="h1"
                component="span"
                extraClasses={STYLES.heroSpan}
                size="size130"
              />
              <Text
                colour="BrandOrange100"
                text="*"
                align="center"
                variant="h1"
                component="span"
                extraClasses={STYLES.heroAsterisk}
                size="size210"
                onClick={handlePopoverOpen}
                aria-describedby={id}
              />
              <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end"
                modifiers={[
                  {
                    name: 'flip',
                    enabled: false
                  },
                  {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                      altAxis: true,
                      altBoundary: true,
                      tether: true,
                      rootBoundary: 'viewport',
                      padding: 18,
                    },
                  }
                ]}
              >
                <AnimatePresence>
                  {
                    open && (
                      <motion.div
                        className={STYLES.popper}
                        initial={{ opacity: 0, transform: "translateY(3rem)" }}
                        animate={{ opacity: 1, transform: "translateY(0rem)" }}
                        transition={spring}
                      >
                        <Text
                          colour="BrandLime200"
                          text="What’s the digital wilderness?"
                          align="center"
                          variant="h1"
                          component="p"
                          size="size50"
                        />
                        <Text
                          colour="BrandLime200"
                          text="We are constantly connected to the digital world. From social media to online shopping to SaaS for business, our personal and company information is stored and shared on a daily basis. Every online interaction exposes us to potential cybersecurity threats, making it necessary for us to be aware and vigilant about protecting our data."
                          align="center"
                          variant="h1"
                          component="p"
                          size="size16"
                          fontFamily="secondary"
                        />
                        <button
                          className={STYLES.closeBtn}
                          onClick={handlePopoverClose}
                          aria-label="close button"
                        >
                          <Text
                            colour="BrandOrange100"
                            text="X"
                            align="center"
                            component="p"
                            size="size55"
                          />
                        </button>
                      </motion.div>
                    )
                  }
                </AnimatePresence>
              </Popper>
            </Typography>
          </Grid>
        </Grid>
      </Grid >
    </Fragment >
  );
};

export default HomeHero;
